<div class="d-flex align-items-center justify-content-between flex-gap" *ngIf="person">
    <div [class.light-bg]="lightbg">
        <h5>{{ person.firstName + " " + person.lastName }}</h5>
        <h6>">{{ person.function }}</h6>
    </div>

    <div class="position-relative portrait-wrapper" (click)="openDialog()">
        <div [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}"
             class="portrait mx-auto" *ngIf="person.image" [class.pointer]="enableDialog">
        </div>
        <div class="portrait outline" [class.pointer]="enableDialog"></div>
    </div>
</div>


